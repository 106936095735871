import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`CoreMedia Content Cloud supports the so-called hub concept. That means, you have an API which you can use to connect external
systems with the CoreMedia system. At the moment, the following hubs can be used:`}</p>
    <ul>
      <li parentName="ul">{`Content Hub`}</li>
      <li parentName="ul">{`Feedback Hub`}</li>
      <li parentName="ul">{`Commerce Hub`}</li>
      <li parentName="ul">{`Personalization Hub`}</li>
    </ul>
    <p>{`CoreMedia already ships so-called connectors for different use cases. A connector connects third-party systems with the CoreMedia
system through the hub APIs. You will find for example, a YouTube connector which allows to integrate YouTube content or an
Acrolinx connector which gives feedback to improve your content.`}</p>
    <p><img parentName="p" {...{
        "src": "/a4d2f0205741a73c6ba9e71337f932fb/CoreMedia_hubs_diagram_5.svg",
        "alt": null
      }}></img></p>
    <p><em parentName="p">{`CoreMedia System with all the Hubs`}</em></p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://www.coremedia.com/labs"
      }}>{`CoreMedia Website`}</a>{` for an overview of supported connectors.`}</p>
    <h2>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul">{`Get an overview of the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/discover/api/content-hub-api/"
        }}>{`Content Hub API`}</a></li>
      <li parentName="ul">{`Get an overview of the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/discover/api/commerce-api/"
        }}>{`Commerce Hub API`}</a></li>
      <li parentName="ul">{`Get an overview of the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/discover/api/feedback-hub-api/"
        }}>{`Feedback Hub API`}</a></li>
      <li parentName="ul">{`Get an overview of the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/discover/api/personalization-client-api/"
        }}>{`Personalization Hub API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      